import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import $store from '../store/index'
// import Home from '../views/Home.vue'

const routes: Array<RouteRecordRaw> = [
  // {
  //   path: '/',
  //   name: 'Home',
  //   component: Home
  // },
  // {
  //   path: '/about',
  //   name: 'About',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () =>
  //     import(/* webpackChunkName: "about" */ '../views/About.vue')
  // },
  {
    path: '/',
    redirect: '/login'
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('@/main/Login.vue'),
    meta: {
      title: '博客管理系统 | 登录'
    }
  },
  {
    path: '/register',
    name: 'Register',
    component: () => import('@/main/Register.vue'),
    meta: {
      title: '博客管理系统 | 注册'
    }
  },

  {
    path: '/main',
    name: 'Main',
    redirect: '/article'
  },
  // 文章管理
  {
    path: '/article',
    name: 'Article',
    redirect: '/article/articleList',
    component: () => import('@/main/Main.vue'),
    meta: {
      breadcrumbName: '文章管理'
    },
    children: [
      {
        path: 'articleList',
        name: 'ArticleList',
        component: () => import('@/article/ArticleList.vue'),
        meta: {
          title: '博客管理系统 | 文章列表',
          breadcrumbName: '文章列表'
        }
      },
      {
        path: 'addArticle',
        name: 'AddArticle',
        component: () => import('@/article/AddArticle.vue'),
        meta: {
          title: '博客管理系统 | 添加文章',
          breadcrumbName: '添加文章'
        }
      },
      {
        path: 'editArticle/id=:id',
        name: 'EditArticle',
        component: () => import('@/article/EditArticle.vue'),
        meta: {
          title: '博客管理系统 | 编辑文章',
          breadcrumbName: '编辑文章'
        }
      }
    ]
  },
  // 分类管理
  {
    path: '/category',
    name: 'Category',
    redirect: '/category/categoryList',
    component: () => import('@/main/Main.vue'),
    meta: {
      breadcrumbName: '分类管理'
    },
    children: [
      {
        path: 'categoryList',
        name: 'CategoryList',
        component: () => import('@/category/CategoryList.vue'),
        meta: {
          title: '博客管理系统 | 分类列表',
          breadcrumbName: '分类列表'
        }
      }
    ]
  },
  // 标签管理
  {
    path: '/tag',
    name: 'Tag',
    redirect: '/tag/tagList',
    component: () => import('@/main/Main.vue'),
    meta: {
      breadcrumbName: '标签管理'
    },
    children: [
      {
        path: 'tagList',
        name: 'TagList',
        component: () => import('@/tag/TagList.vue'),
        meta: {
          title: '博客管理系统 | 标签列表',
          breadcrumbName: '标签列表'
        }
      }
    ]
  },
  // 信息管理
  {
    path: '/message',
    name: 'Message',
    redirect: '/message/commentList',
    component: () => import('@/main/Main.vue'),
    meta: {
      breadcrumbName: '信息管理'
    },
    children: [
      {
        path: 'commentList',
        name: 'CommentList',
        component: () => import('@/message/CommentList.vue'),
        meta: {
          title: '博客管理系统 | 评论列表',
          breadcrumbName: '评论列表'
        }
      },
      {
        path: 'leaveWordList',
        name: 'LeaveWordList',
        component: () => import('@/message/LeaveWordList.vue'),
        meta: {
          title: '博客管理系统 | 留言列表',
          breadcrumbName: '留言列表'
        }
      },
      {
        path: 'likeList',
        name: 'LikeList',
        component: () => import('@/message/LikeList.vue'),
        meta: {
          title: '博客管理系统 | 点赞列表',
          breadcrumbName: '点赞列表'
        }
      }
    ]
  },
  // 工具管理
  {
    path: '/tools',
    name: 'Tools',
    redirect: '/tools/toolsList',
    component: () => import('@/main/Main.vue'),
    meta: {
      breadcrumbName: '工具管理'
    },
    children: [
      {
        path: 'toolsList',
        name: 'ToolsList',
        component: () => import('@/tools/ToolsList.vue'),
        meta: {
          title: '博客管理系统 | 工具列表',
          breadcrumbName: '工具列表'
        }
      },
      {
        path: 'toolsCategoryList',
        name: 'ToolsCategoryList',
        component: () => import('@/tools/ToolsCategoryList.vue'),
        meta: {
          title: '博客管理系统 | 工具分类列表',
          breadcrumbName: '工具分类列表'
        }
      }
    ]
  },
  // 爱的管理
  {
    path: '/love',
    name: 'Love',
    redirect: '/love/loveRecordList',
    component: () => import('@/main/Main.vue'),
    meta: {
      breadcrumbName: '爱的管理'
    },
    children: [
      {
        path: 'loveRecordList',
        name: 'LoveRecordList',
        component: () => import('@/love/LoveRecordList.vue'),
        meta: {
          title: '博客管理系统 | 爱的故事列表',
          breadcrumbName: '爱的故事列表'
        }
      },
      {
        path: 'timeMachineList',
        name: 'TimeMachineListList',
        component: () => import('@/love/TimeMachineListList.vue'),
        meta: {
          title: '博客管理系统 | 时光机列表',
          breadcrumbName: '时光机列表'
        }
      }
    ]
  },
  {
    path: '/test',
    name: 'Test',
    component: () => import('../test/test.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to) => {
  // console.log(to)
  if (to.matched.length === 2) {
    $store.commit('editOpenKeys', to.matched[0].path)
    $store.commit('editSelectedKeys', to.matched[1].path)
  }

  const breadcrumbArray = []
  for (let i = 0; i < to.matched.length; i++) {
    breadcrumbArray.push(to.matched[i].meta.breadcrumbName)
  }
  $store.commit('editbreadcrumb', breadcrumbArray)
})

export default router

import { createStore } from 'vuex'

export default createStore({
  state: {
    openKeys: ['article'],
    selectedKeys: ['/article/articleList'],
    breadcrumbArray: []
  },
  mutations: {
    editOpenKeys(state, path) {
      state.openKeys[0] = path
    },
    editSelectedKeys(state, path) {
      state.selectedKeys[0] = path
    },
    editbreadcrumb(state, breadcrumbArray) {
      state.breadcrumbArray = breadcrumbArray
    }
  },
  actions: {},
  modules: {}
})
